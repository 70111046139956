import { For, Show } from "solid-js";
import { BrandCard } from "../brand/brand_card";
import { Mode } from "~/types";

export function HorizontalScrollCard(props: {
  title: string;
  mode: Mode;
  brandList?: {
    id: string;
    brandThumbnailUrl: string;
    brandTitle: string;
    brandCategory?: string;
    discountPercentage?: number;
    brandKey: string;
    showHubbleCoinReward?: boolean;
  }[];
}) {
  return (
    <Show when={props.brandList && props.brandList.length > 0}>
      <div class="my-6 ml-auto mr-auto lg:max-w-[1136px]">
        <div class="flex flex-row items-center px-4 lg:px-0">
          <h2 class="text-nowrap text-bold text-basePrimaryDark lg:text-h4">
            {props.title}
          </h2>
          <div class="ml-4 h-[1px] w-full bg-baseTertiaryDark"></div>
        </div>
        <div class="no-scrollbar mt-5 grid grid-flow-col grid-rows-2 content-start items-start gap-x-3 gap-y-6 overflow-x-auto px-4 md:grid-cols-5 lg:grid-flow-row lg:grid-cols-6 lg:gap-x-8 lg:px-0">
          <For each={props.brandList}>
            {(brand) => (
              <BrandCard
                mode={props.mode}
                imageUrl={brand.brandThumbnailUrl}
                name={brand.brandTitle}
                categoryName={brand.brandCategory}
                discountPercentage={brand.discountPercentage}
                brandKey={brand.brandKey}
                showHubbleCoinReward={brand.showHubbleCoinReward}
              />
            )}
          </For>
        </div>
      </div>
    </Show>
  );
}
