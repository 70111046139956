import { Title } from "@solidjs/meta";
import {
  Accessor,
  createEffect,
  createMemo,
  createSignal,
  Match,
  onCleanup,
  onMount,
  Setter,
  Show,
  Suspense,
  Switch,
} from "solid-js";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";
import { RNR_PAGE_TITLE } from "~/data/config";
import {
  BrandListData,
  HomePageRouteData,
} from "~/server/data/home_page_route_data";
import {
  axisFloorBgDesktop,
  axisFloorBgMobile,
  axisV2DesktopBg,
  axisV2MobileBg,
} from "~/assets/assets";
import { CategoryGridCard, CategoryStrip } from "../home/category_card";
import occasionsData from "~/data/groups/occasions.json";
import recipientsData from "~/data/groups/recipients.json";
import { zIndexes } from "~/z_indexes";
import { useIsRouting, useSearchParams } from "@solidjs/router";
import {
  BrandsListForDesktop,
  BrandsListForMobile,
  SingleBrandCard,
} from "./brands_card";
import { CoinsBalancePill, getGiftCardText, Name } from "./components";
import { Spacer } from "~/widgets/spacer";
import { Header } from "../brand_l2/header";
import { HubbleBranding } from "~/widgets/branding";
import { Product } from "~/server/types/search";
import { DottedLoader } from "~/widgets/loader";

export default function HomeComponentV2(props: {
  routeData: Accessor<HomePageRouteData | undefined>;
  isClientAxis: Accessor<boolean | undefined>;
  latestCoinsBalance: Accessor<number | undefined>;
  setRefreshLatestBalance: Setter<boolean>;
}) {
  const [showCategoryGrid, setShowCategoryGrid] = createSignal(true);
  const isRouting = useIsRouting();
  const [showCoinsPillOnHeader, setShowCoinsPillOnHeader] = createSignal(false);

  const [queryParams, _setQueryParams] = useSearchParams();
  let maxBrands = parseInt((queryParams.maxBrands as string) ?? "0");

  const allBrands: Accessor<Product[]> = createMemo(() => {
    if (!props.routeData()) return [];

    const brands = props
      .routeData()!
      .brandList.filter((item) => "showAllBrandsTitle" in item)
      .flatMap((item) => item.brands);

    if (maxBrands > 0) {
      return brands.slice(0, maxBrands);
    }

    return brands;
  });

  let categoryObserver: IntersectionObserver;
  let observerCategoryGridRef: HTMLDivElement | undefined = undefined;

  let exploreBrandsRef: HTMLDivElement | undefined;

  const scrollToElement = () => {
    if (exploreBrandsRef) {
      exploreBrandsRef.scrollIntoView({ behavior: "smooth" });
    }
  };

  onMount(() => {
    categoryObserver = new IntersectionObserver((entries) => {
      if (
        entries[0].boundingClientRect.height === 0 &&
        entries[0].boundingClientRect.width === 0
      ) {
        return;
      }
      if (entries[0].isIntersecting) {
        setShowCategoryGrid(true);
      } else {
        setShowCategoryGrid(false);
      }
    });

    onCleanup(() => {
      if (observerCategoryGridRef) {
        categoryObserver.unobserve(observerCategoryGridRef);
      }
    });
  });

  createEffect(() => {
    if (props.routeData() && !isRouting()) {
      if (observerCategoryGridRef) {
        categoryObserver.observe(observerCategoryGridRef);
      }
    }
  });

  function showCategoriesForMobile(): boolean {
    return false;
    // (props.routeData()?.categories ?? false) &&
    // props.routeData()?.categories != null &&
    // brandsCount() >= 9
    //         !config.customizedSDKClients.limitedCategoryContent.includes(
    //           props.routeData()?.clientId!
    //         )
  }

  function showCategoriesForDesktop(): boolean {
    return false;
    // (props.routeData()?.categories ?? false) &&
    // props.routeData()?.categories != null &&
    // brandsCount() >= 9
    // // &&
    // !config.customizedSDKClients.limitedCategoryContent.includes(
    //   props.routeData()?.clientId!
    // )
  }

  function showSearchForMobile(): boolean {
    return false;
  }

  function showSearchForDesktop(): boolean {
    return true;
  }

  const getCoins = createMemo<number>(() => {
    return (
      props.latestCoinsBalance() ??
      props.routeData()?.coins?.totalAvailable ??
      0
    );
  });

  return (
    <Suspense
      fallback={
        <div class="flex flex-col items-center justify-center">
          <DottedLoader color="#999" />
        </div>
      }
    >
      <div class="relative flex min-h-screen flex-col items-center justify-start  ">
        <ClientOnlyComponent component={ClientComponent.ModalHost} />
        <Title>{RNR_PAGE_TITLE}</Title>

        <Header
          isLoggedIn={() => true}
          mode={props.routeData()?.mode ?? "rnr"}
          coins={getCoins}
          showCoins={showCoinsPillOnHeader}
          setRefreshCoins={props.setRefreshLatestBalance}
          clientId={props.routeData()?.clientId}
          showActions={false}
          hideSearchBar={allBrands().length == 1}
        />

        <Show
          when={allBrands() && !isRouting()}
          fallback={<DottedLoader color="#999" />}
        >
          <div
            class="mt-5 "
            classList={{
              "sm:mt-[16px]": allBrands().length == 1,
              "sm:mt-[40px]": allBrands().length > 1,
            }}
          ></div>
          <div class="hidden w-full flex-col items-center justify-start sm:flex">
            <DesktopUI />
          </div>
          <div class="flex w-full flex-col items-center justify-start md:hidden">
            <MobileUI />
          </div>

          <Show when={allBrands().length > 1}>
            <div class="mt-[70px]"></div>
            <div class="absolute bottom-0 w-full">
              <HubbleBranding class=" w-full py-4" />
            </div>
          </Show>
        </Show>
        <Show when={allBrands().length == 1}>
          <div
            class={`fixed bottom-0 flex h-[200px] w-full bg-[image:var(--mobile-image-url)] bg-cover bg-center sm:h-[338px] sm:bg-[image:var(--desktop-image-url)]`}
            style={{
              "background-size": "100% 100%",
              "--mobile-image-url": `url(${axisFloorBgMobile})`,
              "--desktop-image-url": `url(${axisFloorBgDesktop})`,
            }}
          ></div>
        </Show>
      </div>
    </Suspense>
  );

  function MobileUI() {
    return (
      <>
        <div class="w-full px-3">
          <div
            class="flex w-full flex-col items-center justify-start rounded-b-3xl  p-4 "
            style={
              allBrands().length > 1
                ? {
                    "background-image": `url(${axisV2MobileBg})`,
                    "background-size": "100% 100%",
                  }
                : {}
            }
          >
            <Name name={props.routeData()?.name ?? ""} />
            <Spacer height={8} />
            <span class="max-w-[319px] text-center font-jakartaSans text-[24px] font-semibold   ">
              {HeaderText()}
            </span>
            <div class="mt-[16px]"></div>
            {buildCoinsPill({
              isMobile: true,
            })}
          </div>
        </div>
        <Switch>
          <Match when={showCategoriesForMobile()}>
            <Spacer height={16} />
            {buildCategoriesForMobile()}
            <Spacer height={32} />
          </Match>
          <Match when={true}>
            <Spacer height={24} />
            <Show when={allBrands().length > 1}>
              {getGiftCardText()}
              <Spacer height={20} />
            </Show>
          </Match>
        </Switch>
        <Switch>
          <Match when={allBrands().length > 1}>
            {BrandsListForMobile({
              allBrands: allBrands,
            })}
          </Match>
          <Match when={allBrands().length == 1}>
            {SingleBrandCard({
              brand: () => allBrands()[0],
            })}
          </Match>
        </Switch>
      </>
    );
  }

  function HeaderText() {
    if (allBrands().length === 1) {
      return (
        <>
          {`Generate ${allBrands()[0].voucherProduct.title} gift`} card(s) using
          your coins
        </>
      );
    }
    return <>Shop on your favourite brands with coins</>;
  }

  function DesktopUI() {
    return (
      <>
        <Show when={allBrands().length > 1}>
          <div
            class="absolute top-0 h-[700px] w-full   "
            style={`
           background-image: url(${axisV2DesktopBg});
           background-size: 100% 100%;
           `}
          ></div>
        </Show>
        <Name name={props.routeData()?.name ?? ""} />
        <Spacer height={8} />

        <span class="z-10 max-w-[670px] text-center font-jakartaSans text-[48px] font-semibold    ">
          {HeaderText()}
        </span>
        <div class="mt-8"></div>
        {buildCoinsPill({
          isMobile: false,
        })}
        <Switch>
          <Match when={showCategoriesForDesktop()}>
            <div class="mt-[49px]"></div>
            {buildCategoriesForDesktop()}
          </Match>
          <Match when={allBrands().length > 1}>
            <div class="mt-[44px]"></div>
            {getGiftCardText()}
          </Match>
        </Switch>
        <div class="mt-8"></div>
        <Switch>
          <Match when={allBrands().length > 1}>
            {BrandsListForDesktop({
              allBrands: allBrands(),
            })}
          </Match>
          <Match when={allBrands().length == 1}>
            {SingleBrandCard({
              brand: () => allBrands()[0],
            })}
          </Match>
        </Switch>
      </>
    );
  }

  function buildCategoriesForMobile() {
    return (
      <>
        <div
          class={`fixed top-20 w-full overflow-y-visible`}
          style={{
            "z-index": zIndexes.categoryStrip,
          }}
        >
          <Show
            when={
              showCategoriesForMobile() &&
              !showCategoryGrid() &&
              props.routeData()?.categories &&
              props.routeData()?.categories != null
            }
          >
            <CategoryStrip
              categories={props.routeData()!.categories.data}
              maxCategoriesOnStrip={Math.min(
                10,
                props.routeData()!.categories.data.length
              )}
              scroll={true}
              border={false}
              occasions={occasionsData.occasions}
              recipients={recipientsData.recipients}
            />
          </Show>
        </div>
        <Show when={showCategoriesForMobile()}>
          <div ref={observerCategoryGridRef} class="w-full px-4">
            <CategoryGridCard
              categories={props.routeData()?.categories!.data ?? []}
              occasions={occasionsData.occasions}
              recipients={recipientsData.recipients}
            />
          </div>
        </Show>
      </>
    );
  }

  function buildCategoriesForDesktop() {
    return (
      <div
        class={`sticky top-[104px]`}
        style={{
          "z-index": zIndexes.categoryStrip,
        }}
      >
        <CategoryStrip
          categories={props.routeData()!.categories.data}
          maxCategoriesOnStrip={Math.min(
            10,
            props.routeData()!.categories.data.length
          )}
          scroll={false}
          border={true}
          occasions={occasionsData.occasions}
          recipients={recipientsData.recipients}
        />
      </div>
    );
  }

  function buildCoinsPill(pillProps: { isMobile: boolean }) {
    return (
      <CoinsBalancePill
        latestCoinsBalance={getCoins}
        mode="rnr"
        setRefreshLatestBalance={props.setRefreshLatestBalance}
        userName={props.routeData()?.name ?? ""}
        isMobile={pillProps.isMobile}
        startObserve={() => {
          return !!props.routeData() && !isRouting();
        }}
        setShowCoinsPillOnHeader={setShowCoinsPillOnHeader}
      />
    );
  }
}
