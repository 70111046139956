import { A } from "@solidjs/router";
import { HubbleEvent, webEventManager } from "~/data/events";
import { Spacer } from "~/widgets/spacer";

export default function SmartGcStrip() {
  return (
    <A
      href="https://www.amazon.in/dp/B0DTKGS7QN"
      target="_blank"
      onClick={() => {
        webEventManager.sendEvent(HubbleEvent.CLICK_SMART_GC_STRIP);
      }}
    >
      <div class="flex cursor-pointer flex-row items-center justify-between bg-smartGcStripBgMobile bg-cover bg-no-repeat px-4 py-2 text-[#853800]   md:justify-center lg:bg-smartGcStripBgDesktop lg:px-5 lg:py-3 lg:[background-size:100%] ">
        <div class="flex flex-row items-center">
          <NewButton />
          <Spacer width={8} />
          <div class="hidden  lg:block">
            <DesktopStrip />
          </div>
          <div class="block lg:hidden">
            <MobileStrip />
          </div>
        </div>
        <Spacer width={4} />
        <ArrowRight />
      </div>
    </A>
  );

  function DesktopStrip() {
    return (
      <span class="cursor-pointer whitespace-nowrap text-nowrap  text-[15px] font-semibold leading-[140%]">
        {"Smart Greeting Cards "}
        <span class="cursor-pointer text-nowrap lg:text-[13px] lg:font-normal">
          {": A top-up & share gift card"}
        </span>
      </span>
    );
  }

  function MobileStrip() {
    return (
      <span class="inline-block cursor-pointer  text-[12px] font-semibold leading-[140%]">
        {"Smart Greeting Cards:"}
        <br />
        <span class="cursor-pointer text-nowrap lg:text-[13px] lg:font-normal">
          A top-up & share gift card
        </span>
      </span>
    );
  }

  function NewButton() {
    return (
      <div
        class="flex h-[28px] items-center rounded-md  border border-white bg-[#E0153E] px-1.5 py-0.5 lg:h-[21px] "
        style={{
          "box-shadow": "0px 2px 3px 0px rgba(152, 95, 76, 0.13)",
        }}
      >
        <span class="text-f12w700  tracking-[0.96px] text-baseTertiaryLight">
          NEW
        </span>
      </div>
    );
  }

  function ArrowRight() {
    return <i class={"ph-bold ph-arrow-right text-[16px] "}></i>;
  }
}
