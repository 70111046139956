import { Accessor, For, Match, Switch } from "solid-js";
import HubbleImage from "~/widgets/hubble_image";
import { VoucherGenerationBrandCard } from "../brand_l2/voucher_generation_brand_card";
import { Product } from "~/server/types/search";
import { A } from "@solidjs/router";
import { placeholderCard } from "~/assets/assets";
import { ButtonDarkV2 } from "../login-rnr/login_rnr_axis_component";

type BrandCardProps = {
  plainLogoUrl: string;
  cardBackgroundColor: string;
  heroImageUrl?: string;
  title: string;
  brandId: string;
};

export function BrandCard(props: BrandCardProps) {
  return (
    <A href={"/buy-gift-card/" + props.brandId} class="w-full">
      <div class="w-full cursor-pointer rounded-[16px] sm:rounded-[18px] sm:border-[2px] sm:border-baseTertiaryMedium sm:hover:border-baseDark">
        <Switch>
          <Match when={props.heroImageUrl}>
            <HubbleImage
              class="aspect-[541/316]  w-full rounded-[14px] sm:h-[210px] sm:rounded-2xl"
              src={props.heroImageUrl!}
              alt={props.title}
            />
          </Match>
          <Match when={true}>
            <div class="sm:h-[210px] sm:w-[358px] ">
              <VoucherGenerationBrandCard
                plainLogoUrl={props.plainLogoUrl}
                cardBackgroundColor={props.cardBackgroundColor}
                showHubbleLogo={false}
                borderClass="rounded-[14px] sm:rounded-2xl"
              />
            </div>
          </Match>
        </Switch>
      </div>
    </A>
  );
}

export function BrandsListForDesktop(props: { allBrands: Product[] }) {
  return (
    <div class="flex w-full flex-col items-center justify-start gap-6 ">
      <For
        each={getSortedBrandsListForDesktop({
          allBrands: props.allBrands,
        })}
      >
        {(brandList) => (
          <div class="flex flex-row items-center justify-center gap-6">
            <For each={brandList}>
              {(brand) => (
                <BrandCard
                  cardBackgroundColor={brand.voucherProduct.cardBackgroundColor}
                  plainLogoUrl={brand.voucherProduct.plainLogoUrl}
                  title={brand.voucherProduct.title}
                  heroImageUrl={brand.voucherProduct.heroImageUrl ?? undefined}
                  brandId={brand.id}
                />
              )}
            </For>
          </div>
        )}
      </For>
    </div>
  );
}

export function BrandsListForMobile(props: { allBrands: Accessor<Product[]> }) {
  return (
    <Switch>
      <Match when={props.allBrands().length < 9}>
        <div class="flex w-full flex-col items-start justify-start gap-5 px-4">
          <For each={props.allBrands()}>
            {(brand) => (
              <BrandCard
                cardBackgroundColor={brand.voucherProduct.cardBackgroundColor}
                plainLogoUrl={brand.voucherProduct.plainLogoUrl}
                title={brand.voucherProduct.title}
                heroImageUrl={brand.voucherProduct.heroImageUrl ?? undefined}
                brandId={brand.id}
              />
            )}
          </For>
        </div>
      </Match>
      <Match when={true}>
        <div class="grid w-full grid-cols-2 flex-col items-start justify-start gap-6 px-4 ">
          <For each={props.allBrands()}>
            {(brand) => (
              <A href={"/buy-gift-card/" + brand.id}>
                <HubbleImage
                  src={brand.voucherProduct.iconImageUrl}
                  class="inline-block aspect-[53/59] h-fit w-full  rounded-2xl"
                  alt={brand.voucherProduct.title + " logo image"}
                  errorImage={placeholderCard}
                />
              </A>
            )}
          </For>
        </div>
      </Match>
    </Switch>
  );
}

function getSortedBrandsListForDesktop(props: {
  allBrands: Product[];
}): Product[][] {
  const totalbrandsCount = props.allBrands?.length ?? 0;

  if (props.allBrands.length === 4) {
    return [props.allBrands.slice(0, 2), props.allBrands.slice(2, 4)];
  }

  const listOfBrandList: Product[][] = [];
  for (let i = 0; i < totalbrandsCount; i += 3) {
    listOfBrandList.push(props.allBrands?.slice(i, i + 3));
  }

  return listOfBrandList;
}

export function SingleBrandCard(props: { brand: Accessor<Product> }) {
  return (
    <div class="flex w-full flex-row items-center justify-center px-3 sm:pb-[50px]">
      <div class="z-10  flex w-full flex-col gap-3 rounded-3xl border border-[#313538] bg-baseLight p-3 sm:w-max   sm:gap-4 sm:rounded-[28px] sm:p-4">
        <A
          href={"/buy-gift-card/" + props.brand().id}
          class="w-full cursor-pointer"
        >
          <Switch>
            <Match when={props.brand().voucherProduct.heroImageUrl}>
              <HubbleImage
                class="aspect-[541/316] w-full  rounded-xl sm:h-[258px]  sm:rounded-2xl"
                src={props.brand().voucherProduct.heroImageUrl!}
                alt={props.brand().voucherProduct.title}
              />
            </Match>
            <Match when={true}>
              <div class="sm:h-[210px] sm:w-[358px] ">
                <VoucherGenerationBrandCard
                  plainLogoUrl={props.brand().voucherProduct.plainLogoUrl}
                  cardBackgroundColor={
                    props.brand().voucherProduct.cardBackgroundColor
                  }
                  showHubbleLogo={false}
                />
              </div>
            </Match>
          </Switch>
        </A>
        <ButtonDarkV2
          onClick={() => {
            window.location.href = "/buy-gift-card/" + props.brand().id;
          }}
          isLoading={false}
        >
          Select gift card amount
        </ButtonDarkV2>
      </div>
    </div>
  );
}
