import GradientText from "~/widgets/gradient_text";

export default function HomeRaisoniHeader() {
  const purpleFontStyle =
    "text-[32px] sm:text-[44px] font-semibold leading-[1.3] tracking-[-0.64px] sm:tracking-[-0.88px] text-[#5C0F8B]";
  return (
    <div class={`flex flex-col items-center justify-center ${purpleFontStyle}`}>
      <div class="mt-7 sm:mt-4"></div>
      <span class="text-bold font-medium text-successDark">Welcome</span>
      <div class="mt-2"></div>
      <span class="flex">Enjoy exclusive</span>
      <span class="flex">
        <GradientText gradient="linear-gradient(269deg, #FC5000 2.94%, #5C0F8B 99.2%)">
          Raisonian discount
        </GradientText>
      </span>
      <span class="flex">on 450+ brands</span>
      <div class="mt-[12px] sm:mt-[22px]"></div>
    </div>
  );
}
